
$(function () {
    document_ready();
});

function document_ready() {
    ts = (new Date()).getTime();

    responsive_init();

    $("body").addClass("ready").removeClass("no-transition");

    init_event_handlers();
    bind_widgets();

    te = (new Date()).getTime();
    console.log("On-Ready Load Time: ", te - ts);
}

$(window).on('load', function () {
    scroll_animations();
});

$(window).scroll(function () {
    scroll_animations();
});

$(window).on("resize", function (e) {
    responsive_update(null, true);
    scroll_animations();
});

function init_event_handlers() {
    fix_touch_hovers();
    itag_init();
    fix_100vh_init();
    icons_load();
    goto_init();
    scroll_animations(true);
    blazy_init();
    click_touch_init();
    header_init();
    toggle_element_init();
    expand_it_init();
    tabs_init();
    scroll_load_init();
    misc_init();
    form_process_init();
    upload_init();
    sort_dom_init();
}

function bind_widgets(context) {
    img_to_bg(null, context);
    fancybox_init();
    checkbox_plain_init(null, context);
    textfield_autosize_init(null, context);
    validate_init(context);
    sticky_init(null, context);
    priority_blocks_init(null, context);
    slider_init(null, context);
}