
/*

 Click and touch events helpers

 */

function click_touch_init() {

    $(document).on("click.touchfocused", ".touchevents .js-touch-focused, .js-touch-focused-all", function (e) {
        var $o = $(this);
        if (!$o.hasClass("focused")) {
            if (!$o.hasClass("disable-prevent-link-clicks")) {
                e.preventDefault();
            }
            $(".js-touch-focused").not($o).not($o.closest(".js-touch-focused")).removeClass("focused");
            $o.addClass("focused");
        }
        else {
            if ($o.hasClass('js-touch-focused-toggle')) {
                $o.removeClass("focused");
            }
        }
    });

    $(document).on("click", ".touchevents .js-touch-focused a, .js-touch-focused-all a", function (e) {
        var $tf = $(this).closest(".js-touch-focused");
        if (!$tf.hasClass("focused") && !$tf.hasClass("disable-prevent-link-clicks")) {
            e.preventDefault();
        }
    });

    $(document).on("click touchstart", "*", function (e) {
        if (!$(e.target).closest(".js-touch-focused").length) {
            $(".js-touch-focused").removeClass("focused");
        }
    });

    $(document).on("click", ".js-prevent-default", function (e) {
        e.preventDefault();
    });

    $(document).on("click", ".js-stop-propagation", function (e) {
        e.stopPropagation();
    });

}



/*

Responsive design helpers

 */

function responsive_init() {
    responsive_update();
}

function responsive_update(force, no_animation) {
    if (typeof no_animation !== 'undefined' && no_animation) {
        $("body").addClass('no-transition no-animation');
    }
    if (typeof force === "undefined") force = false;
    var ww = window.innerWidth;
    if ($("body").data("ww") != ww || force)
    {
        if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
            Blazy.revalidate();
        }
        $("[data-place]").each(function(){
            var places = $(this).data("place");
            var breakpoints = Object.keys(places).map(function(value) {
                return parseInt(value);
            }).sort(function(a,b) {
                return a - b;
            }).reverse();
            for (i in breakpoints) {
                if (window.matchMedia("(min-width: "+breakpoints[i]+"px)").matches) {
                    if ($(places[breakpoints[i]]).length)  {
                        if (!$(this).next(places[breakpoints[i]]).length) {
                            $(this).attr("data-place-breakpoint", breakpoints[i]).insertBefore(places[breakpoints[i]]);
                        }
                    }
                    break;
                }
            }
        });

        $("[data-placeholder][data-placeholder-desktop]").each(function(){
            if (window.matchMedia("(min-width: 768px)").matches) {
                $(this).attr("placeholder", $(this).attr("data-placeholder-desktop"));
            }
            else
            {
                $(this).attr("placeholder", $(this).attr("data-placeholder"));
            }
        });

        $("body").trigger("responsive-update", [force, no_animation]);
        if (no_animation) {
            $("body").trigger("responsive-update-no-animation", [force, no_animation]);
        }

        $("body").data("ww", ww);
    }
    if (typeof no_animation !== 'undefined' && no_animation) {
        $("body")[0].offsetHeight;
        $("body").removeClass('no-transition no-animation');
    }
}


/*

Scroll Lazy Loading

 */

function blazy_init() {

    $('.js-blazy-revalidate-on-scroll').on('scroll', function(){
        blazy_revalidate_on_scroll_delay(function(){
            if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
                Blazy.revalidate();
            }
        }, 50);
    });

    window.Blazy = new Blazy({
        offset: 0,
        success: function(ele){
            var $o = $(ele).closest(".img-to-bg");
            img_to_bg($o);
            $o.addClass('img-to-bg-lazy-loaded');
            //console.log('Blazy', ele, 'success');
            blazy_callback(ele);
        }
        , error: function(ele, msg){
            //console.log('Blazy', ele, msg);
            blazy_callback(ele);
            if(msg === 'missing'){
                //console.log(ele, msg);
            }
            else if(msg === 'invalid'){
                //console.log(ele, msg);
            }
        }
    });

    $(window).on('resize', function(){
        blazy_revalidate_delay(function(){
            blazy_revalidate();
        }, 100);
    });

    $(document).on('shown.bs.modal', function () {
        blazy_revalidate();
    });

    $('.js-slick-slider-revalidate-image').on('init reInit setPosition', function(event){
        blazy_revalidate();
    });
}

function blazy_callback(ele) {
    var callback = $(ele).not('.blazy-callback-executed').data('src-callback');
    if (callback) {
        var callback_params = $(ele).data('src-callback-params');
        if (!callback_params) {
            callback_params = [];
        }
        var fn = getFunctionFromString(callback);
        if (typeof fn === "function") {
            $(ele).addClass('blazy-callback-executed');
            fn.apply(ele, callback_params);
        }
    }
}

function blazy_revalidate() {
    if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
        Blazy.revalidate();
    }
}

var blazy_revalidate_delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

var blazy_revalidate_on_scroll_delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

// Get function from string, with or without scopes (by Nicolas Gauthier)
window.getFunctionFromString = function(string)
{
    var scope = window;
    var scopeSplit = string.split('.');
    for (i = 0; i < scopeSplit.length - 1; i++)
    {
        scope = scope[scopeSplit[i]];

        if (scope == undefined) return;
    }

    return scope[scopeSplit[scopeSplit.length - 1]];
};




/*

Set Background Image depending on img content inside it

 */

function img_to_bg($o, context) {

    if (!$("body").hasClass("img-to-bg-inited")) {

        $(window).on("resize", function(){
            delay_img_to_bg(function(){
                img_to_bg($(".img-to-bg-resp"));
            }, 100);
        });

        $("body").addClass("img-to-bg-inited");
    }

    if (typeof $o === "undefined" || !$o) $o = $(".img-to-bg", context);
    $o.each(function () {
        var $imgtobg = $(this);
        var $img = $imgtobg.find("> img").first();
        if (!$img.length) {
            $img = $imgtobg.find("> picture img").first();
        }
        var src = "";
        if ($img.length) {
            var src = $img[0].currentSrc;
        }
        if (!src) {
            src = $img.attr('src');
        }
        if ($img.length && src) {
            $imgtobg.css("background-image", "url('" + src + "')");
            $imgtobg.addClass("img-to-bg--inited");
        }
    });
}

var delay_img_to_bg = (function(){
    var timer = 0;
    return function(callback, ms){
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    };
})();


/*

Scroll to needed objects

 */

function goto_init() {

    if (location.hash && location.hash.length) {
        try {
            var hash = location.hash.substr(1);
            if (hash) {
                var $o = $('[id="'+hash+'"]').first();
                if ($o.length) {
                    var h = $('.js-goto-offset-on-load').not('.js-goto-offset').first().outerHeight();
                    goto_object($o, 0, (h)?-1*h:0);
                    scroll_activate();
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    $(document).on("click", ".js-goto", function (e) {
        var href = $(this).attr("href");
        var hash = href.split('#');
        if (hash.length > 1) {
            hash = '#'+hash.pop();
            var $o = $(hash);
            if ($o.length) {
                e.preventDefault();
                var $this = $(this);
                if ($this.closest(".goto-list").length)
                {
                    $this.closest(".goto-list").find("li").removeClass("active");
                    $this.closest("li").addClass("active");
                    tabs_update_pointer($this.closest(".tabs"));
                }
                if ($this.closest(".goto-hash-change").length)
                {
                    location_hash_update($this.attr("href"));
                }
                goto_object($o, undefined, undefined, $this);
            }
        }
    });
}

function goto_object($o, speed, doffset, $trigger)
{
    if (typeof speed === 'undefined') {
        speed = 700;
    }
    if ($o.length)
    {
        var offset = $o.offset().top;
        if (typeof doffset !== 'undefined') {
            offset += doffset;
        }
        var $offset = $(".js-goto-offset").first();
        if ($offset.length) {
            offset -= $offset.outerHeight();
        }
        if ($o.data("goto-offset-element")) {
            offset -= $($o.data("goto-offset-element")).outerHeight();
        }
        goto_offset(offset, speed, $o, $trigger);
    }
}

function goto_offset(offset, speed, $o, $trigger)
{
    if (typeof speed === 'undefined') {
        speed = 700;
    }
    $('body').trigger('goto-start', [offset, speed, $o, $trigger]);
    $("html, body").stop(true, true).animate({scrollTop: offset}, speed, function(){
        $(this).trigger('goto-end', [offset, speed, $o, $trigger]);
        if ($o) {
            $o.trigger('goto-end', [offset, speed, $o, $trigger]);
        }
    });
}


/*

Scroll activate

 */

function scroll_activate() {

    if (!$("body").hasClass("scroll-activate-inited")) {

        $(document).on('goto-start', function(e, offset, speed, $o, $trigger){
            if ($trigger && $trigger.length && $trigger.closest('.js-scroll-activate').length) {
                activate_goto_link($trigger);
            }
            delay(function(){
                window['scroll-activate-locked'] = true;
                delay(function(){
                    window['scroll-activate-locked'] = false;
                }, 800);
            }, 0);
        });

        $(document).on('goto-end', function(){
            delay(function(){
                window['scroll-activate-locked'] = false;
                scroll_activate();
            }, 0);
        });

        $("body").addClass("scroll-activate-inited");
    }

    var st = $(window).scrollTop();
    var dh = 0;//$(".js-goto-offset").outerHeight();
    var dw = $(window).height() / 3;
    var is_page_bottom = $(".wrap").outerHeight() - $(window).height() - st < 100;
    var $gtl = $(".js-scroll-activate");
    var f_this = this;
    if ($gtl.length && !window['scroll-activate-locked']) {
        $gtl.each(function(){
            if ($(this).closest('.sticksy-dummy-node').length && $gtl[0].offsetParent) {
                return true;
            }
            var $gtl_a = $(this).find("a");
            var t_offset = 0;
            if (st > t_offset) {
                var $active = false;
                var index = -1;
                $gtl_a.each(function(i) {
                    var $this = $(this);
                    var href = $(this).attr("href").split('#');
                    if (href[1]) {
                        href = '#'+ href[1];
                    }
                    else {
                        return false;
                    }
                    if (href.length < 2) {
                        return false;
                    }
                    var $o = $(href);
                    var o_h = $o.outerHeight();
                    if (!o_h && $o.data('scroll-activate-stop')) {
                        $o_s_e = $($o.data('scroll-activate-stop'));
                        o_h = $o_s_e.offset().top - $o.offset().top;
                    }
                    if ($o.length && st + dw >= $o.offset().top - dh && st + dw < $o.offset().top + o_h - dh) {
                        index = i;
                        $active = $this;
                        return false;
                    }
                });
                if (is_page_bottom) {
                    index = $gtl_a.length - 1;
                    $active = $gtl_a.last();
                }
                if ($active && (typeof f_this.hmai === "undefined" || index != f_this.hmai)) {
                    f_this.hmai = index;
                    activate_goto_link($active);
                }
            } else {
                f_this.hmai = -1;
                activate_goto_link($gtl_a.first());
            }
        });
    }
}

function activate_goto_link($obj) {
    $obj.addClass("active").siblings().removeClass("active");
    $obj.closest("li").addClass("active").siblings().removeClass("active");
    $obj.trigger("link-activated");
}


/*

Actions on Scroll

 */

function scroll_animations(force) {
    body_scrolled(force);
    scroll_activate();
}

function body_scrolled(force) {
    requestAnimationFrame(function(){
        var $o = $(".js-header-on-scroll");
        if ($o.length) {
            scroll_back_header($o, force);
        }
    });
}

function scroll_back_header($o, force) {
    var h_offset = $('.js-header-start').offset().top;
    var h_height = $('.js-header-height').height();
    var y_offset = window.pageYOffset;
    this.menuScrolled = y_offset > h_offset;
    if (!this.oldScrollPosition) {
        this.menuHidden = y_offset > h_offset + h_height;
    }
    else {
        this.menuHidden = y_offset > h_offset + h_height && y_offset > this.oldScrollPosition;
    }
    if (y_offset != this.oldScrollPosition || force) {
        $o.toggleClass("header--scrolled", this.menuScrolled);
        if (this.menuHidden && !this.menuScrolledHeight) {
            $o.css('transition', 'none');
        }
        $o.toggleClass("header--hidden", this.menuHidden);
        if (this.menuHidden && !this.menuScrolledHeight) {
            $o[0].offsetHeight;
            $o.css('transition', '');
        }
        if (!this.oldScrollPosition) {
            this.menuScrolledHeight = y_offset > h_offset + h_height;
        }
        else {
            this.menuScrolledHeight = y_offset > h_offset && y_offset < this.oldScrollPosition || y_offset > h_offset + h_height && y_offset > this.oldScrollPosition;
        }
        $o.toggleClass("header--scrolled-height", this.menuScrolledHeight);
    }
    this.oldScrollPosition = y_offset;
}

function scroll_back_header_hide($o) {
    var h_offset = $('.js-header-start').offset().top;
    var h_height = $('.js-header-height').height();
    var y_offset = window.pageYOffset;
    this.menuHidden = y_offset > h_height;
    $o.toggleClass("header--hidden", this.menuHidden);
}



/*

Header Init

*/

function header_init() {

    $(document).on("click", ".js-menu-switcher", function (e) {
        e.preventDefault();
        $(".js-menu-switcher-target").toggleClass("menu-overlay-active");
        if ($(".js-menu-switcher-target").hasClass("menu-overlay-active")) {
            bodyScrollLock.disableBodyScroll($('.js-menu-overlay .js-menu-overlay-scroll')[0]);
            setTimeout(function(){
                blazy_revalidate();
            }, 500);
        }
        else {
            bodyScrollLock.enableBodyScroll($('.js-menu-overlay .js-menu-overlay-scroll')[0]);
        }
    });

    $(document).on("click", ".js-menu-hide", function (e) {
        e.preventDefault();
        menu_overlay_hide();
    });

    $(document).on("click", "*", function (e) {
        if (!$(e.target).closest(".js-menu-overlay, .js-menu-switcher").length) {
            if ($(".js-menu-switcher-target").hasClass("menu-overlay-active")) {
                menu_overlay_hide();
            }
        }
    });

    // $(document).on("touchmove", ".js-menu-overlay", function (e) {
    //     e.stopPropagation();
    // });
    //
    // $(window).on("scroll", function (e) {
    //     if ($("html").hasClass("menu-overlay-active")) {
    //         menu_overlay_hide();
    //     }
    // });

}

function menu_overlay_hide() {
    $(".js-menu-switcher-target").removeClass("menu-overlay-active");
    bodyScrollLock.enableBodyScroll($('.js-menu-overlay .js-menu-overlay-scroll')[0]);
}



/*

Fix buggy 100vh behaviour on mobiles

 */


var fix_100vh_w = -1;
var fix_100vh_h = -1;

function fix_100vh_init() {
    if (!Modernizr.touchevents) return;

    fix_100vh();
    $("body").data("fix_100vh_ww", $(window).width());

    $(window).on("resize", function (e) {
        if (fix_100vh_w != window.innerWidth || fix_100vh_h != window.innerHeight) {
            fix_100vh();
        }
        fix_100vh_w = window.innerWidth;
        fix_100vh_h = window.innerHeight;
    });
}

function fix_100vh() {
    var h = window.innerHeight;
    $(".js-fix-100vh").each(function(){
        $(this).css("height", h);
    });
    $(".js-fix-100vh-minus-header").each(function(){
        $(this).css("height", h - $('.header + .header-replace')[0].offsetHeight);
    });
    $(".js-fix-100vh-min").each(function(){
        $(this).css("min-height", h);
    });
    $(".js-fix-100vh-max").each(function(){
        $(this).css("max-height", h);
    });
}




/*

Expanded Blocks Functionality

 */

function expand_it_init()
{
    window['expand_it_window_width'] = $(window).width();
    $(window).on("resize", function () {
        if($(this).width() != window['expand_it_window_width']){
            expand_it_init_prepare(null, true);
            window['expand_it_window_width'] = $(this).width();
        }
    });

    $(document).on("click expand-it", ".expand-it", function(e){
        if (!$(this).data('no-prevent-default')) {
            e.preventDefault();
        }
        var $o = $($(this).data("expand-selector"));
        if (!$o.length)
        {
            $o = $(this).closest(".expand-it-wrapper").find(".expand-it-container")
        }
        if (!$o.length)
        {
            $o = $(this).closest(".expand-it-container");
        }
        if (!$o.length)
        {
            $o = $($(this).attr("href"));
        }
        if (!$o.length) return;

        var $this = $(this);
        $o.each(function(){
            expand_it_trigger($this, $(this), e.type === "expand-it");
        });
    });

    $(document).on("-webkit-transitionend transitionend", ".expand-it-container", function(e){
        var $o = $(e.target);
        if ($o.hasClass('expand-it-container')) {
            if ($o.hasClass("before-transition")) {
                expand_it_height_check($o);
            }
            expand_it_init_prepare($o.closest('.expand-it-container'), true);
            $(e.target).removeClass("before-transition");
            $(e.target).trigger("expandAfter");
        }
    });

    if (location.hash && location.hash.substr(0, 1).match(/\w/i))
    {
        if ($(location.hash).filter(".expand-it-wrapper").length)
        {
            var $o = $(location.hash);
            var $loc_link = $(".expand-it[href='"+location.hash+"']");
            if (!$loc_link.length)
            {
                $loc_link = $o.filter(".expand-it-wrapper").find(".expand-it");
            }
            if ($loc_link.not(".active").filter(":visible").length)
            {
                setTimeout(function(){
                    $loc_link.trigger("click");
                }, 300)
            }
        }
    }

    $(document).on("expand-it-open", function (e) {
        if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
            Blazy.revalidate();
        }
    });
}

function expand_it_height_check($o) {
    if ($o.hasClass("expand-it-container") && !$o.hasClass("expand-it-container-overflow-hidden"))
    {
        var height_default = 0;
        var current_height = parseInt($o.css("max-height"), 10);
        if ($o.find(".expand-it-container-height-default").length)
        {
            height_default = $o.find(".expand-it-container-height-default").height();
        }
        $o.toggleClass("overflow-visible", current_height > height_default);
    }
    if ($o.hasClass("expand-it-container") && $o.hasClass("expand-it-container-max-height-auto"))
    {
        var id = $o.attr("id");
        setTimeout(function(){
            removeCSSRule("rule-"+id);
        }, 300);
    }
}

function expand_it_trigger($handler, $o, soft) {

    var id = $o.attr("id");
    if (!id)
    {
        id = "id"+(new Date()).getTime() + $o.text().length;
        $o.attr("id", id);
    }
    if ($o.hasClass('expand-it-container-ignore')) {
        return;
    }
    expand_it_init_prepare($o, true);
    height = $o.find(".expand-it-inner").eq(0).outerHeight(true);
    $o[0].offsetHeight;
    $o.addClass("no-transition-only-this");
    $o[0].offsetHeight;
    if (!$o.hasClass("expand-it-container-overflow-hidden"))
    {
        $o.removeClass("overflow-visible");
    }
    if ($o.hasClass("active"))
    {
        $o.removeClass("overflow-visible");
    }
    $o[0].offsetHeight;
    $o.removeClass("no-transition-only-this");
    if (!$o.hasClass("active") && !$("#rule-"+id).length) {
        addCSSRule("rule-"+id, "#"+id+".active { max-height: "+ height+"px; }");
    }
    $o[0].offsetHeight;

    if ($handler.attr("data-label")) {
        var label = $handler.html();
        $handler.html($handler.attr("data-label"));
        $handler.attr("data-label", label);
    }
    $handler.toggleClass("active", !$o.hasClass("active"));
    $(".expand-it.active[href='#"+$o.attr("id")+"']").not($handler).toggleClass("active", !$o.hasClass("active"));

    var $wrapper = $o.closest(".expand-it-wrapper");

    if (!soft)
    {
        $o.trigger("expandBefore");
        $o.addClass("before-transition").toggleClass("active").siblings(".expand-it-container").each(function(){
            $(".expand-it.active[href='#"+$handler.attr("id")+"']").trigger("expand-it");
            $handler.addClass("before-transition").removeClass("active");
        });
        var is_active = $o.hasClass("active");
        $wrapper.toggleClass("active", is_active);
        if ($wrapper.hasClass("expand-it-wrapper-collapse-siblings"))
        {
            $wrapper.siblings(".expand-it-wrapper").each(function(){
                var $this = $(this);
                var $this_c = $(this).find(".expand-it-container");
                if ($(this).find(".expand-it").length)
                {
                    $(this).find(".expand-it.active").trigger("expand-it");
                }
                else
                {
                    $this_c.each(function(){
                        var $this_c_this = $(this);
                        var $this_other = $(".expand-it.active[href='#"+$(this).attr("id")+"']");
                        if ($this_other.length) {
                            $this_other.trigger("expand-it");
                        }
                        else {
                            var $this_other = $this.find('.expand-it-pseudo')/*.filter(function() {
                            return $(this).parent($this).length === 0;
                        })*/;
                            if ($this_other.length) {
                                $this_c.each(function() {
                                    expand_it_trigger($(this), $this_c_this, true);
                                });
                            }
                        }
                        if ($this_other.length) {
                            $this_other.removeClass("active");
                        }
                    });
                }
                $this_c.addClass("before-transition").removeClass("active");
                $(this).removeClass("active");
            });
            if ($wrapper.hasClass("active")) {
                setTimeout(function(){
                    var $sticky = $(".js-goto-offset-expand");
                    var offset = 0;
                    if ($sticky.length) {
                        $sticky.each(function(){
                            offset = Math.max(offset, $sticky.outerHeight() + parseInt($sticky.css('top'), 10));
                        });
                    }
                    if ($wrapper.offset().top < $(window).scrollTop() + offset) {
                        goto_object($wrapper, 400, -1*offset - parseInt($wrapper.css('margin-top'), 10));
                    }
                }, 400);
            }
        }
        if (($o.hasClass("active") || $o.hasClass("expand-it-container--scroll-to-always")) && $handler.hasClass("expand-it-scroll-to"))
        {
            goto_object($o, 500, 'easeInOutQuad');
        }
        if ($handler.hasClass("expand-it-hash-change"))
        {
            if (is_active)
            {
                if ($handler.attr("href"))
                {
                    location_hash_update($handler.attr("href"));
                }
                else if ($wrapper.attr("id"))
                {
                    location_hash_update("#" + $wrapper.attr("id"));
                }
            }
            else
            {
                var $tabpane = $handler.closest(".tab-pane");
                if ($tabpane.length && $tabpane.attr("id"))
                {
                    location_hash_update("#"+$tabpane.attr("id"));
                }
                else
                {
                    location_hash_remove();
                }
            }
        }
        $o.trigger('expand-it-'+((is_active)?'open':'close'));
    }
}

function expand_it_init_prepare($c, force) {
    if (typeof $c === "undefined" || !$c) $c = $(".expand-it-container");
    if (!force) {
        $c = $c.not(".expand-it-container-prepared");
    }
    var rules = '';
    $c.each(function(){
        var $o = $(this);
        var id = $o.attr("id");
        if (!id)
        {
            id = "id"+(new Date()).getTime() + $o.text().length;
            $o.attr("id", id);
        }
        //height = $o.find(".expand-it-inner").outerHeight(true);
        height = $o.find(".expand-it-inner")[0].clientHeight;
        rules += " #"+id+".active { max-height: "+ height+"px; } ";
        $o.addClass("expand-it-container-prepared");
    });
    if (rules) {
        addCSSRule("rule-expand-it", rules);
    }
}





/*

Tabs

 */

function tabs_init() {

    $(document).on("click", ".js-tabs a", function (e, eparams) {
        var $tabs = $(this).closest(".js-tabs");

        $(this).addClass("active").siblings('a').removeClass("active").end().parent().siblings().removeClass("active").find('a').removeClass("active");
        if ($(this).closest(".js-tabs-toggle").length) {
            $(this).parent().toggleClass("active");
        } else {
            $(this).parent().addClass("active");
        }

        tabs_update_pointer($tabs);

        $ostt = $(this).closest(".js-tabs-onclick-scroll-to-tabs");
        if ($ostt.length) {
            $("html, body").stop(true, true).animate({scrollTop: $ostt.offset().top + ($ostt.data("tabs-scroll-offset") ? $ostt.data("tabs-scroll-offset") : 0) - ($ostt.data("tabs-scroll-offset-element") ? $($ostt.data("tabs-scroll-offset-element")).outerHeight() : 0)}, 500);
        }

        if ($(this).hasClass("js-tabs-ignore") || $tabs.hasClass("js-tabs-ignore")) {
            return true;
        }


        var href = $(this).attr("href");
        if (href.length > 1) {
            var $o = $(href);
        }
        else {
            var $o = $($tabs.data('tabs-panes')).find('.tab-pane').eq($(this).parent().prevAll().length);
        }

        if ($o && $o.length) {
            e.preventDefault();
            if ($(this).closest(".js-tabs-toggle").length) {
                $o.toggleClass("active");
            } else {
                $o.addClass("active");
            }

            $o.siblings(".tab-pane").removeClass("active");
            if ($o.hasClass("active")) {
                $o.closest(".tab-content-change-height").css("height", 0).css("height", $o.outerHeight());
                $o.trigger('tabs-show');
            } else {
                $o.closest(".tab-content-change-height").css("height", 0);
                $o.trigger('tabs-hide');
            }
            if ($(this).closest(".js-tabs").hasClass("js-tabs-hash-change")) {
                if (typeof eparams === "undefined" || typeof eparams.nohash === "undefined" || !eparams.nohash) {
                    location_hash_update($(this).attr("href"));
                }
            }
            $o.filter(".active").find(":input:first").trigger("check-form");
            $o.siblings(".tab-pane").find(":input.error").each(function () {
                var $form = $(this).closest("form");
                var validator = $form.data("validator");
                if (validator) $(this).valid();
            });
            $(window).trigger("scroll.fixed-hscroll");
        }
    });

    $(document).on("tabs-show", function (e) {
        if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
            Blazy.revalidate();
        }
    });

    tabs_scroll_init();
    // $(document).ajaxStop(function () {
    //     tabs_scroll_init();
    // });

    if (location.hash) {
        if ($(location.hash).filter(".tab-pane").length) {
            $(".js-tabs a[href='" + location.hash + "']").trigger("click");
        } else if ($(".tab-pane").find(location.hash).length) {
            $(".js-tabs a[href='#" + $(".tab-pane").find(location.hash).closest(".tab-pane").attr("id") + "']").trigger("click", {nohash: true});
        }
    }

    tabs_update_pointer($(".tabs"));
    $(".js-tabs-fixed-center a").on("click-tabs-fixed-center", function (e) {
        tabs_update_pointer($(this).closest(".tabs"));
    });
    $(window).on("resize orientationchange", function () {
        tabs_update_pointer($(".tabs"));
        $(".js-tabs-fixed-center-scroll").trigger("scroll.emulate");
    });
}

function tabs_scroll_init() {
    $(".js-tabs-fixed-center-scroll").not(".js-tabs-fixed-center-scroll-inited").on("scroll scroll.emulate", function (e) {
        var $tabs = $(this).closest(".js-tabs-fixed-center");
        var direction = ($(this).css('overflow-x') == 'auto') ? 'horz' : 'vert';
        var scroll_val = (direction == 'vert') ? $(this).scrollTop() : $(this).scrollLeft();
        var scroll_size = (direction == 'vert') ? $(this).outerHeight() : $(this).outerWidth();
        var scroll_inner_size = (direction == 'vert') ? $(this)[0].scrollHeight : $(this)[0].scrollWidth;
        if (scroll_val <= 0) {
            $tabs.addClass("scroll-in-start");
        } else {
            $tabs.removeClass("scroll-in-start");
        }
        if (scroll_val + scroll_size >= scroll_inner_size - 1) {
            $tabs.addClass("scroll-in-end");
        } else {
            $tabs.removeClass("scroll-in-end");
        }
    }).addClass("js-tabs-fixed-center-scroll-inited").trigger("scroll.emulate").closest(".js-tabs-fixed-center").addClass('js-tabs-fixed-center-inited');

    $(".js-tabs-fixed-center a").not(".js-tabs-fixed-center-scroll-inited").on("click click-tabs-fixed-center", function (e) {
        var $tabs = $(this).closest(".js-tabs-fixed-center");
        var $ul = $(this).closest(".js-tabs-fixed-center-scroll");
        $ul.stop(true, true).animate({scrollLeft: $(this).parent()[0].offsetLeft - $tabs.width() / 2 + $(this).outerWidth() / 2}, 300);
    }).addClass("js-tabs-fixed-center-scroll-inited");

    $(".js-tabs-scroll-prev").not(".js-tabs-fixed-center-scroll-inited").on("click", function (e) {
        e.preventDefault();
        var $tabs = $(this).closest(".js-tabs-fixed-center");
        var $ul = $tabs.find(".js-tabs-fixed-center-scroll");
        $ul.stop(true, true).animate({scrollLeft: $ul.scrollLeft() - $ul.width() * 0.75}, 300);
    }).addClass("js-tabs-fixed-center-scroll-inited");

    $(".js-tabs-scroll-next").not(".js-tabs-fixed-center-scroll-inited").on("click", function (e) {
        e.preventDefault();
        var $tabs = $(this).closest(".js-tabs-fixed-center");
        var $ul = $tabs.find(".js-tabs-fixed-center-scroll");
        $ul.stop(true, true).animate({scrollLeft: $ul.scrollLeft() + $ul.width() * 0.75}, 300);
    });

    $(".js-tabs-fixed-center li.active a").trigger("click-tabs-fixed-center");
}

function tabs_update_pointer($tabs) {
    $tabs.each(function () {
        var $pointer = $(this).find(".js-tabs-pointer");
        var $scroll = $pointer.closest(".tabs__scroll");
        var scroll_offset = 0;
        if ($pointer.length) {
            if ($scroll.length) {
                scroll_offset = $scroll[0].scrollLeft;
            }
            $pointer.css({
                left: $(this).find("li.active").offset().left - $(this).offset().left + scroll_offset,
                width: $(this).find("li.active").width(),//outerWidth
            }).addClass("active");
        }
    });
}



/*

Hide/Show blocks

 */

function toggle_element_init()
{
    $(document).on("click click-pseudo change toggle-hide", ".js-toggle-element, .js-show-element, .js-hide-element", function (e) {
        if ($(this).is("a")) e.preventDefault();
        if (e.type != "change" && $(this).is(":checkbox,:radio")) {
            return true;
        }
        var $o = $();
        if ($(this).attr("href") && $(this).attr("href").length > 1 && $(this).attr("href").slice(0, 1) === '#') {
            $o = $($(this).attr("href"));
        }
        if (!$o.length) {
            $o = $($(this).data("selector"));
        }
        if (!$o.length) {
            $o = $(this).closest('.js-toggle-element-wrapper').find('.js-toggle-element-target');
        }
        var restore_scroll = $(this).data("toggle-restore-scroll-position");
        if (restore_scroll) {
            var scroll_pos = $(window).scrollTop();
            var $scroll_relative = $(this);
            var scroll_relative = $(this).data('toggle-restore-scroll-relative');
            if (scroll_relative) {
                $scroll_relative = $(scroll_relative);
            }
            var offset_pos = parseInt($scroll_relative.offset().top, 10);
        }
        var class_name = "hidden";
        if ($(this).data("toggle-class")) {
            class_name = $(this).data("toggle-class");
        }
        var element_class_name = "active";
        if (typeof $o.data("toggle-element-class") !== 'undefined') {
            element_class_name = $o.data("toggle-element-class");
        }
        if (typeof $(this).data("toggle-element-class") !== 'undefined') {
            element_class_name = $(this).data("toggle-element-class");
        }
        toggle_element_close_all($o);
        $o.data('toggle-element', $(this));
        var change_label = false;
        if (($(this).hasClass("js-toggle-element") && e.type != "toggle-hide") || (e.type == "toggle-hide" && $o.hasClass(element_class_name))) {
            change_label = true;
            if ($(this).is(":checkbox,:radio")) {
                if (e.type == "change") {
                    $o.toggleClass(class_name, !$(this).prop("checked"));
                }
            }
            else {
                $(this).toggleClass(element_class_name);
                $o.toggleClass(class_name);
            }
            $o.data('toggle-class', class_name);
            $o.data('toggle-element-class', element_class_name);
            $o.trigger("toggle-element");
        }
        if ($(this).data("toggle-close-other") && e.type != "toggle-hide") {
            var group = $(this).data("toggle-group");
            $('.js-toggle-element, .js-show-element').not(this).filter('[data-toggle-group="'+group+'"]').each(function(){
                $(this).trigger('toggle-hide');
            });
        }
        if ($(this).hasClass("js-show-element")) {
            $o_s = $($(this).data("selector-show"));
            if ($o_s.length) {
                $o = $o_s;
            }
            if (!$(this).hasClass(element_class_name)) change_label = true;
            $(this).addClass(element_class_name);
            $o.toggleClass(class_name, !!$(this).data("toggle-reverse"));
            $o.trigger("show-element");
        }
        if ($(this).hasClass("js-hide-element")) {
            $o_h = $($(this).data("selector-hide"));
            if ($o_h.length) {
                $o = $o_h;
            }
            if ($(this).hasClass(element_class_name)) change_label = true;
            $(this).addClass(element_class_name);
            $o.toggleClass(class_name, !$(this).data("toggle-reverse"));
            $o.trigger("hide-element");
        }
        if (change_label && $(this).data("toggle-change-label")) {
            var label = $(this).html();
            $(this).html($(this).attr("data-toggle-change-label"));
            $(this).attr("data-toggle-change-label", label);
        }
        var $siblings = $();
        if ($(this).data("toggle-siblings-selector")) {
            $siblings = $(this).closest($(this).data("toggle-siblings-selector")).siblings();
        }
        if ($(this).data("toggle-current-selector")) {
            $siblings = $siblings.find($(this).data("toggle-current-selector"));
        }
        if ($siblings.length) {
            $siblings.removeClass(element_class_name);
        }
        if (restore_scroll) {
            var scroll_pos_new = $(window).scrollTop();
            var offset_pos_new = parseInt($scroll_relative.offset().top, 10);
            $(window).scrollTop(offset_pos_new - (offset_pos - scroll_pos));
        }
    });

    $(document).on("click", "*", function (e) {
        var $telement = $(e.target).closest(".js-toggle-element");
        if (!$telement.length && !$(e.target).closest(".js-toggle-element-target").length) {
            toggle_element_close_all();
        }
    });

    /*$(document).on("click", "*", function (e) {
        toggle_element_close_on_blur_delay(function(){
            if (!$(e.target).closest(".js-toggle-element").length && !$(e.target).closest(".js-toggle-element-target").length) {
                $(".js-toggle-element-target[data-toggle-element-close-on-blur]").each(function(){
                    var classname = $(this).data('toggle-class');
                    if (!classname) classname = 'hidden';
                    $(this).removeClass(classname);
                    $(this).trigger("toggle-element");
                    if ($(this).data('toggle-element')) {
                        $($(this).data('toggle-element')).removeClass($(this).data('toggle-element-class'));
                    }
                });
            }
        }, 0);
    });*/
}

function toggle_element_close_all($except) {
    if (!$except || !$except.length) {
        $except = $();
    }
    $(".js-toggle-element-target[data-toggle-element-close-on-blur]").not($except).each(function(){
        var classname = $(this).data('toggle-class');
        if (!classname) classname = 'hidden';
        $(this).removeClass(classname);
        $(this).trigger("toggle-element");
        if ($(this).data('toggle-element')) {
            $($(this).data('toggle-element')).removeClass($(this).data('toggle-element-class'));
        }
    });
}

var toggle_element_close_on_blur_delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();



function misc_init() {

    $(document).on('toggle-element', '.hsearch', function(){
        if ($(this).hasClass('active')) {
            $(this).find('input').focus();
        }
    });

    $(document).on('click', '.js-clear-input', function(e){
        var $o = $($(this).data('input'));
        $o.val('');
    });

    $(document).on('click', '.js-comment-controls-toggle', function(e){
        e.preventDefault();
        var $to_hide = $(this).closest('.js-comment-controls-wrapper').find('.js-comment-controls-to-hide');
        if ($to_hide.length) {
            expand_it_trigger($(this), $to_hide);
        }
        var $to_show = $(this).closest('.js-comment-controls-wrapper').find('.js-comment-controls');
        if ($to_show.length) {
            expand_it_trigger($(this), $to_show);
        }
    });

    $(document).on('mouseenter', '.js-hover-trigger', function(e){
        $(this).closest('.js-hover-target').addClass('hover');
    });

    $(document).on('mouseleave', '.js-hover-trigger', function(e){
        $(this).closest('.js-hover-target').removeClass('hover');
    });

    $(document).on('click', '.js-goto-link-inside a', function(e){
        if (!$(this).hasClass('js-goto-ignore')) {
            var href = $(this).attr("href");
            var hash = href.split('#');
            if (hash.length > 1) {
                hash = hash.pop();
                try {
                    var $o = $('[id="'+hash+'"]').first();
                    if ($o.length) {
                        e.preventDefault();
                        goto_object($o);
                        location_hash_update(href);
                    }
                }
                catch (e) {
                    console.log(e);
                }
            }
        }
    });

    $(document).on('click', '.js-filter', function(e){
        e.preventDefault();
        var $wrapper = $(this).closest('.js-filter-wrapper');
        var $section = $(this).closest('.js-filter-section');
        var filter = $(this).attr('data-filter');
        var $all = $('.js-items-to-filter');
        var $f = $all.filter('[data-filter="'+filter+'"]');
        $all.removeClass('hidden');
        $('.js-filter').removeClass('active');
        $('.js-filter-section').removeClass('active');
        $section.toggleClass('active', !!$f.length);
        $wrapper.toggleClass('active', !!$f.length);
        $wrapper.find('.js-filter-title').removeClass('hidden');
        $wrapper.find('.js-filter-reset').addClass('hidden');
        $section.find('.js-filter-title').toggleClass('hidden', !!$f.length);
        $section.find('.js-filter-reset').toggleClass('hidden', !$f.length);
        if ($f.length) {
            $all.addClass('hidden');
            $f.removeClass('hidden');
            $(this).addClass('active');
        }
    });

    $(document).on('scroll-load-after', '.js-gardener-load-container', function(e, response){
        var is_gardener = !!$(response).data('gardener');
        console.log(is_gardener);
        // $('body').toggleClass('gardener', is_gardener);
        // if (is_gardener) {
        //     if (!$('#gardener-css').length) {
        //         $('head').append('<link id="gardener-css" rel="stylesheet" href="'+$('body').data('gardener-css')+'" type="text/css" media="all">');
        //     }
        // }
        // else {
        //     $('#gardener-css').remove();
        // }
        $('.menu-overlay').toggleClass('gardener-mode', is_gardener);
        $('.menu-overlay').toggleClass('not-gardener-mode', !is_gardener);
    });




}




/*

Scroll Load Functionality

 */

function scroll_load_init() {

    $(window).on("load scroll", function () {
        scroll_load();
    });

    $(document).on("click", ".js-scroll-load-trigger", function (e) {
        e.preventDefault();
        var options = options_default = {
            wrapper: false,
            container: $(this).attr("data-load-container"),
            initiator: $(this),
            onscroll: false
        };
        var options_new = $(this).data("options");
        if (options_new) {
            var options = $.extend({}, options_default, options_new);
        }
        scroll_load(options);
    });
}

function scroll_load(options) {
    var options = $.extend({}, options);
    if (!options.wrapper) $w = $(window);
    else $w = $(options.wrapper);
    if (!options.container) $c = $(".js-scroll-load");
    else $c = $(options.container);
    if (options.initiator) $i = $(options.initiator);
    if (typeof options.onscroll === "undefined") options.onscroll = true;
    if (options.force_loading) {
        $c.removeClass("scroll-loading-end");
    }
    if (typeof options.force_loading === "undefined" || !options.force_loading) $c = $c.not(".scroll-loading-end");

    $c.each(function () {
        var $this = $(this);
        var inactive_class = $this.data("scroll-load-inactive-class")?$this.data("scroll-load-inactive-class"):"inactive";
        var offset_top = $this.offset().top;
        var delta_offset = $w.height() / 2;
        if ($w.not($(window)).length) offset_top = 0;
        var $hide_on_last_load = $($this.attr("data-hide-on-last-load"));
        if (!$this.hasClass("scroll-loading") && (!$this.hasClass("scroll-loading-end") || options.force_loading) && ((offset_top >= 0 && (offset_top + $this.outerHeight() < $w.scrollTop() + $w.height() + delta_offset)) || !options.onscroll)) {
            var page = $this.attr("data-scroll-load-page");
            if (typeof page === "undefined") page = 0;
            var url = $this.data("scroll-load-url");
            if (options.url) url = options.url;

            if (typeof url === 'undefined') return;

            if ($this) {
                var xhr = $this.data('xhr');
                if (xhr && xhr.readyState != 4) {
                    xhr.abort();
                }
            }

            var xhr = $.ajax({
                url: url,
                type: "get",
                data: {page: page},
                beforeSend: function () {
                    if (options.initiator && $i) loader_add($i);
                    if (options.loader_to) {
                        var $loader_to = $(options.loader_to);
                        if ($loader_to.length) {
                            loader_add($loader_to);
                            $loader_to[0].offsetHeight;
                        }
                    }
                    $this.addClass("scroll-loading");
                    if (options.empty) {
                        $this.addClass("scroll-loading-empty");
                    }
                    $this.trigger('scroll-load-before');
                },
                complete: function () {
                    if (options.initiator && $i) loader_remove($i);
                    if (options.loader_to) {
                        var $loader_to = $(options.loader_to);
                        if ($loader_to.length) {
                            loader_remove($loader_to);
                            $loader_to[0].offsetHeight;
                        }
                    }
                    $this.removeClass("scroll-loading scroll-loading-empty");
                    $this.trigger('scroll-load-complete');
                },
                success: function (response) {
                    var $response = $(response);
                    if ($this.data("scroll-load-insert-before")) {
                        $response.insertBefore($this.data("scroll-load-insert-before"));
                    }
                    else {
                        if (options.empty) {
                            $this.empty();
                        }
                        $this.append($response);
                    }
                    bind_widgets($response);
                    responsive_update(true);
                    $this.attr("data-scroll-load-page", page * 1 + 1);
                    $hide_on_last_load.removeClass(inactive_class);
                    if (!(response && !$response.find(".scroll-load-last-one").length && !$response.filter(".scroll-load-last-one").length)) {
                        $this.addClass("scroll-loading-end");
                        $hide_on_last_load.addClass(inactive_class);
                    }
                    scroll_load_after($this, $response);
                },
                error: function (jqXHR, textStatus) {
                    console.log('Ошибка при загрузке');
                    $this.trigger('scroll-load-error', [jqXHR]);
                }
            });
            $this.data('xhr', xhr);
        }
    });

    function scroll_load_after($this, $response) {
        $this.find(".scroll-to-activate").not(".active").each(function (i) {
            $(this).addClass("scroll-to-activate-delay-" + i).removeClass("hidden");
        });
        $this[0].offsetHeight;
        $this.find(".scroll-to-activate").not(".active").addClass("active");
        if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
            Blazy.revalidate();
        }
        $this.trigger('scroll-load-after', [$response]);
    }
}




/*

itag Update Functionality

 */

function itag_init() {

    $(window).on("load scroll resize", function (e) {
        itag_update_delay(function(){
            itag_update(e.type == 'resize');
        }, 50);
    });
}

itag_init.menu_template =
    '<div class="itag itag--dropdown js-toggle-element-wrapper js-itag-menu">\
        <a class="itag__inner js-toggle-element" data-toggle-class="active" href="#">\
            <svg class="icon">\
                <use xlink:href="#icon-ellipsis"></use>\
            </svg>\
            </a>\
            <div class="itag-dropdown js-toggle-element-target" data-toggle-element-close-on-blur>\
            <div class="itag-menu">\
                <div class="itag-menu__list js-itag-menu-list">\
                </div>\
            </div>\
        </div>\
    </div>';
itag_init.menu_item_template = '<a href="{{url}}" class="itag-menu__item">{{name}}</a>';

function itag_update(force) {
    var st = $(window).scrollTop();
    var h = $(window).height();
    var $o = $('.js-itags');
    var offset_factor = 0.5;
    if (force) {
        $o.removeClass('js-itags-inited');
    }
    $o.not('.js-itags-inited').each(function () {
        var $this = $(this);
        var offset_top = $this.offset().top;
        if (/*true || */offset_top >= 0 && (offset_top + offset_factor*h >= st && offset_top <= st + h + offset_factor*h)) {
            var $items = $this.find('.js-itag');
            var it_h = $this.width();
            var ml = 10;
            var menu_item_width = 36;
            var right_edge = 0;
            var menu_active = false;
            var menu_content = '';
            $this.find('.js-itag-menu').remove();
            $items.each(function(i){
                var it_i_h = $(this).width();
                right_edge += ((i)?ml:0) + it_i_h;
                $(this).toggleClass('itag-out', right_edge > it_h - menu_item_width - ml);
                if (right_edge > it_h - menu_item_width - ml) {
                    menu_active = true;
                    var $a = $(this).find('a');
                    menu_content += itag_init.menu_item_template.replace('{{url}}', $a.attr('href')).replace('{{name}}', $a.html());
                }
            });
            if (menu_active) {
                $items.last().after(itag_init.menu_template);
                if (menu_content) {
                    $this.find('.js-itag-menu-list').append(menu_content);
                    var $d = $this.find('.itag-dropdown');
                    if ($d.length) {
                        var delta = 17;
                        var offset = $d.offset().left;
                        if (offset < delta) {
                            $d.css('right', parseInt(offset - delta, 10));
                        }
                    }
                }
            }
            $this.addClass('js-itags-inited');
        }
    });
}

var itag_update_delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();




/*

Sticky Blocks

 */

function sticky_init($o, context) {

    if (!$("body").hasClass("js-sticky-inited")) {

        $('body').on("responsive-update", function(e, force, noanimation){
            if (force) {
                $(".js-sticky-inited").each(function(){
                    var sticksy = $(this).data('sticksy');
                    if (sticksy) {
                        sticksy.hardRefresh();
                    }
                });
            }
        });

        $("body").addClass("js-sticky-inited");
    }


    if (typeof $o === "undefined" || !$o) $o = $(".js-sticky", context);
    $o.not('.js-sticky-inited').each(function () {
        //if (this.offsetParent !== null) {
            if ($(this).css('position') == 'sticky') {
                var stickyEl = new Sticksy(this, {
                    topSpacing: 20
                    ,listen: true
                });
                stickyEl.onStateChanged = function (state) {
                    if (state === 'fixed') stickyEl.nodeRef.classList.add('sticky-fixed');
                    else stickyEl.nodeRef.classList.remove('sticky-fixed');
                    if (state === 'stuck') stickyEl.nodeRef.classList.add('sticky-stuck');
                    else stickyEl.nodeRef.classList.remove('sticky-stuck');
                }
                $(this).data('sticksy', stickyEl);
                $(this).addClass("js-sticky-inited");
            }
        //}
    });
}



/*

Hide blocks to equal columns with different priority

 */

function priority_blocks_init($o, context) {
    if (typeof $o === "undefined" || !$o) $o = $("[data-priority-wrapper]", context);
    $o.not('.js-priority-inited').each(function () {
        var $c = $(this).find('[data-priority-compare]');
        var $f = $(this).find('[data-priority-fit]');
        var h_c = $c.height();
        var h_f = $f.height();
        var $items = $f.find('[data-priority]');
        var $items_sorted = $($items.toArray().sort(function(a, b){
            var aVal = parseInt(a.getAttribute('data-priority')),
                bVal = parseInt(b.getAttribute('data-priority'));
            return aVal - bVal;
        }));
        var h_summ = 0;
        var delta = 100;
        var h_cur = h_f - h_summ - delta;
        $items_sorted.each(function(){
            var h = $(this).outerHeight(true);
            h_summ += h;
            if (h_cur > h_c) {
                $(this).addClass('hidden');
            }
            else {
                return false;
            }
            h_cur = h_f - h_summ - delta;
        });
        $(this).addClass("js-priority-inited");
    });
}



/*

Upload widget

 */

function upload_init() {

    $(document).on("change", ".js-upload", function (e) { // change
        e.preventDefault();
        var $o = $($(this).data('upload-container'));
        if (!$o.length) {
            $o = $(this).closest('.js-upload-container');
        }
        $(this).removeClass('loaded');
        $o.removeClass('loaded');
        $o.find('.js-upload-img-container img').remove();
        if (this.files.length) {
            $(this).addClass('loaded');
            $o.addClass('loaded');
            $o.find('.js-upload-img-container').append('<img>');
            upload_load_image(this, $o.find('.js-upload-img-container img')[0]);
            $(this).blur();
        }
        // console.log('js-upload change');
    });

    $(document).on("click", ".js-upload-clear", function (e) { // change
        e.preventDefault();
        var $o = $(this).closest('.js-upload-container');
        var $input = $($o.data('upload-input'));
        if (!$input.length) {
            $input = $o.find('.js-upload');
        }
        upload_clear($input);
    });

    $(document).on("dragenter dragover", ".js-upload", function (e) {
        var $o = $(this).closest('.js-upload-container');
        $o.addClass('is-dragover');
    });

    $(document).on("dragleave drop", ".js-upload", function (e) {
        var $o = $(this).closest('.js-upload-container');
        $o.removeClass('is-dragover');
    });
};

function upload_clear(input) {
    var $input = $(input);
    var $o = $($input.data('upload-container'));
    if (!$o.length) {
        $o = $input.closest('.js-upload-container');
    }
    $input.each(function(i){
        var id = 'upload-clear-id'+(new Date()).getTime()+'-'+i;
        $(this).attr('data-upload-clear-id', id);
        $(this).replaceWith($('<div>').append($(this).clone()).html());
        $('[data-upload-clear-id="'+id+'"]').removeClass('loaded').trigger('upload-clear');
    });
    $o.removeClass('loaded');
};

function upload_load_image(input, img) {
    var input, file, fr, img;

    if (typeof window.FileReader !== 'function') {
        alert("The file API isn't supported on this browser yet.");
        return;
    }

    if (!input) {
        alert("Um, couldn't find the imgfile element.");
    }
    else if (!input.files) {
        alert("This browser doesn't seem to support the `files` property of file inputs.");
    }
    else if (!input.files[0]) {
        //alert("Please select a file.");
    }
    else {
        file = input.files[0];
        var accept = input.getAttribute('accept');
        if (!accept || file.type.indexOf(accept.split('*')[0]) === 0 || accept.indexOf(file.type) !== -1) {
            fr = new FileReader();
            fr.onload = createImage;
            fr.readAsDataURL(file);
        }
        else {
            upload_clear(input);
        }
    }

    function createImage() {
        if (img) {
            img.onload = imageLoaded;
            img.src = fr.result;
            $(img).parent().css('background-image', fr.result);
        }
    }

    function imageLoaded() {

    }
};




function sort_dom_init() {

    $(document).on("click", ".js-sort-dom", function (e) {
        e.preventDefault();
        var sort = $(this).data('sort');
        var $items = $(sort.selector);
        var $parent = $items.parent();
        $items.sort(function(a,b){
            var an = $(a).data(sort.param),
                bn = $(b).data(sort.param);

            var delta = (sort.direction == 'desc')?-1:1

            if(an > bn) {
                return 1*delta;
            }
            if(an < bn) {
                return -1*delta;
            }
            return 0;
        });

        $items.detach().appendTo($parent);
    });
}


function filter_dom_init() {

    var delay_filter_dom = (function () {
        var timer = 0;
        return function (callback, ms) {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();

    jQuery.expr[':'].Contains = function(a,i,m){
        return (a.textContent || a.innerText || "").toUpperCase().indexOf(m[3].toUpperCase())>=0;
    };

    $(document).on("keyup", ".js-filter-dom", function (e) {
        e.preventDefault();
        var _this = this;
        delay_filter_dom(function(){
            // ts = (new Date()).getTime();
            var value = $(_this).val();
            var value_raw = value;
            var $items = $($(_this).data('filter-dom'));
            var url = $(_this).data('filter-dom-url');


            // console.log(value);
            var transform = $(_this).data('filter-dom-value-transform');
            if (transform) {
                var transform_params = $(_this).data('filter-dom-value-transform-params');
                if (!transform_params) {
                    transform_params = [];
                }
                transform_params = [value].concat(transform_params);
                // console.log(transform_params);
                var fn = getFunctionFromString(transform);
                if (typeof fn === "function") {
                    value = fn.apply(null, transform_params);
                }
            }

            // console.log(value);

            if (url) {
                var data = {};
                data[$(_this).data('filter-dom-name')] = value;
                xhr = $(_this).data('filter-dom-xhr');
                if (xhr && xhr.readyState != 4) {
                    xhr.abort();
                }
                $(_this).data('filter-dom-xhr', $.ajax({
                    async: false,
                    type: 'get',
                    url: url,
                    data: data,
                    success: function (response) {
                        $items.empty();
                        $items.append(response);
                    },
                    error: function () {
                    }
                }));
            }
            else {
                var closest = $(_this).data('filter-dom-closest');
                var except = $(_this).data('filter-dom-except');
                var all = 0;
                var contains = 0;
                var not_contains = 0;
                if (value) {
                    var $not_contains = $items.filter(":not(:Contains(" + value + "))");
                    if (closest) {
                        $not_contains = $not_contains.closest(closest);
                    }
                    if (except) {
                        $not_contains = $not_contains.not(except);
                    }
                    $not_contains.addClass('hidden');
                    not_contains = $not_contains.length;
                    var $contains = $items.filter(":Contains(" + value + ")");
                    if (closest) {
                        $contains = $contains.closest(closest);
                    }
                    $contains.removeClass('hidden');
                    contains = $contains.length;
                    all = contains+not_contains;
                } else {
                    var $all = $items;
                    if (closest) {
                        $all = $all.closest(closest);
                    }
                    if (except) {
                        $all = $all.not(except);
                    }
                    $all.removeClass('hidden');
                    all = $all.length;
                    contains = all;
                    not_contains = all;
                }
                var after = $(_this).data('filter-dom-after');
                if (after) {
                    var after_params = $(_this).data('filter-dom-after-params');
                    if (!after_params) {
                        after_params = [];
                    }
                    after_params = [value, value_raw, all, contains, not_contains].concat(after_params);
                    var fn = getFunctionFromString(after);
                    if (typeof fn === "function") {
                        value = fn.apply(null, after_params);
                    }
                }
            }

            // te = (new Date()).getTime();
            // console.log("Filter Time: ", te - ts);
        }, 50);
    });
}