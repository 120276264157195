/*
* SVG icon url update
* */

function icons_load() {
    var url = $('body').data('svg-sprite-url');
    var cache_key = $('body').data('svg-sprite-cache-key');
    if (!cache_key) {
        cache_key = url.replace(/\?.+$/g, '').replace(/[^\w\d\-\_]/g, '-');
    }
    $.ajax({
        url: url,
        localCache: true,
        cacheTTL: 1,
        dataType: 'text',
        cacheKey: cache_key
    }).done(function (response) {
        $('body').append('<div id="svg-icons-container" style="height: 0; width: 0; position: absolute; top: -99999px; left: 0; visibility: hidden;">' + response + '</div>');
    });
}


/*

 Loaders (for buttons)

 */

function loader_init() {
    $btn = $(".loader-on-page-load");
    loader_add($btn);
    $btn.prop("disabled", true);
}

function loader_add($o) {
    var loader = '<span class="loader"><svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\
    width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">\
        <path fill="#fff" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">\
        <animateTransform attributeType="xml"\
            attributeName="transform"\
            type="rotate"\
            from="0 25 25"\
            to="360 25 25"\
            dur="0.6s"\
            repeatCount="indefinite"/>\
        </path>\
        </svg></span>';
    if ($o.closest('.btn').length) {
        $o.wrapInner("<div class='btn-loader-inner'>");
    }
    $o.append(loader).addClass("loading");
    var $loader = $o.find("> .loader");
    $loader.addClass("active");
}

function loader_remove($o) {
    $o.find(".btn-loader-inner").children().unwrap();
    $o.removeClass("loading");
    var $loader = $o.find("> .loader");
    $loader.removeClass("active");
    $loader.remove();
}




/*

Gallery and Modal Popups

 */

function fancybox_init() {

    if (!$("html").hasClass("fancybox-inited")) {

        $("html").addClass("fancybox-inited");

        $.fancybox.defaults.lang = $('html').attr('lang');

        if ($().fancybox) {
            $.fancybox.options_default = {
                slideShow: false,
                hash: false,
                loop: true,
                idleTime: 10,
                margin: [44, 0],
                gutter: 50,
                keyboard: true,
                animationEffect: "zoom-in-out",
                transitionEffect: "slide",
                arrows: true,
                infobar: true,
                toolbar: true,
                buttons: [
                    // 'slideShow',
                    // 'fullScreen',
                    // 'thumbs',
                    // 'close'
                ],
                smallBtn: true,
                btnTpl: {
                    smallBtn: '<div data-fancybox-close class="fancybox-close"><svg class="icon icon--cross"><use xlink:href="#icon-cross"></use></svg></div>'
                },
                thumbs: {
                    autoStart: false,
                    hideOnClose: true
                }
            };

            $.fancybox.options_modal = {
                animationDuration: 700,
                parentEl: ".wrap",
                slideShow: false,
                hash: false,
                keyboard: false,
                ajax: {
                    settings: {
                        cache: false
                    }
                },
                hideScrollbar: true,
                baseClass: "fancybox-container--popup",
                // clickSlide: false,
                // clickOutside: false,
                // mobile: {
                //     clickSlide: false,
                // },
                trapFocus: false,
                autoFocus: false,
                touch: false,
                popup_default: true,
                btnTpl: {
                    smallBtn: '<div data-fancybox-close class="fancybox-close"><svg class="icon icon--cross"><use xlink:href="#icon-cross"></use></svg></div>'
                },
                afterLoad: function (instance, current) {
                    current.$content.wrap("<div>");
                    if (current.$content.hasClass('fancybox-content')) {
                        current.$content.removeClass('fancybox-content').parent().addClass('fancybox-content');
                    }
                    bind_widgets(current.$content.parent());
                    if (bodyScrollLock) {
                        $('.fancybox-slide--html').not('.body-scroll-locked').each(function(){
                            bodyScrollLock.disableBodyScroll(this);
                            $(this).addClass('body-scroll-locked');
                        });
                        $('.fancybox-slide--html').find('.body-scroll-to-lock').not('.body-scroll-locked').each(function(){
                            bodyScrollLock.disableBodyScroll(this);
                            $(this).addClass('body-scroll-locked');
                        });
                    }
                },
                afterShow: function (instance, current) {
                    if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
                        Blazy.revalidate();
                    }
                },
                beforeClose: function (instance, current) {
                    if (current.$content) {
                        current.$content.closest(".fancybox-container").addClass('fancybox-is-close');
                    }
                    if (bodyScrollLock) {
                        $('.fancybox-slide--html.body-scroll-locked').add($('.fancybox-slide--html .body-scroll-locked')).each(function(){
                            bodyScrollLock.enableBodyScroll(this);
                            $(this).removeClass('body-scroll-locked');
                            $('body').css('overflow', '');
                        });
                    }
                },
            };

            $.fancybox.defaults.afterLoad = function (instance, current) {
                current.$content.closest('.fancybox-container').addClass('fancybox-container--' + current.type);
            };
            $.fancybox.defaults.beforeLoad = function (instance, current) {
                if ($().tooltipster) {
                    var tooltips = $.tooltipster.instances();
                    $.each(tooltips, function (i, tooltip) {
                        tooltip.close();
                    });
                }
            };
            $.fancybox.defaults.hash = false;
            var lang = $('html').attr('lang');
            var message = 'The requested content cannot be loaded. <br/> Please try again later.';
            switch (lang) {
                case 'ru':
                    message = 'Произошла ошибка при загрузке. <br/> Попробуйте ещё раз.';
                    break;
                default:
            }
            $.fancybox.defaults.errorTpl = '<div><div class="panel panel--compact"><div class="panel__content text-align-center"><p>'+message+'</p></div></div></div>';
        }

        $('body').on('mousewheel', function (e) {
            if ($(".fancybox-is-zoomable").length) {
                e.preventDefault();
                var instance = $.fancybox.getInstance();
                if ($(".fancybox-is-zoomable").length && instance.canPan() && e.deltaY > 0) {
                    instance.scaleToFit();
                } else if ($(".fancybox-can-zoomIn").length && instance.isScaledDown() && e.deltaY < 0) {
                    instance.scaleToActual(e.clientX, e.clientY);
                }
            }
        });
    }

    if ($().fancybox) {

        var options = $.fancybox.options_default;
        $fancybox_links_all = $("[data-fancybox]").not(".fancybox-inited");

        $fancybox_links = $fancybox_links_all.not("[data-type='ajax'], [data-type='inline']");
        fancybox_links_by_group = [];
        groups = [];
        $fancybox_links.each(function () {
            var group = $(this).attr("data-fancybox");
            if (!group) group = "";
            if ($.inArray(group, groups) < 0) groups.push(group);
        });
        for (group in groups) {
            options_current = $.extend(true, {}, options);
            var $items = $fancybox_links.filter("[data-fancybox='" + groups[group] + "']");
            var $first = $items.eq(0);
            if (typeof $first.attr("data-fancybox-loop") !== "undefined") {
                options_current["loop"] = $first.data("fancybox-loop");
            }
            $items.fancybox(options_current).addClass("fancybox-inited");
        }

        $fancybox_links_ajax = $fancybox_links_all.filter("[data-type='ajax'], [data-type='inline']");
        $fancybox_links_ajax.each(function () {
            var options = $.fancybox.options_modal;
            if ($(this).data('ajax-type')) {
                options = $.extend({}, options, {
                    ajax: {
                        settings: {
                            type: $(this).data('ajax-type'),
                        }
                    }
                });
            }
            if ($(this).data('ajax-data')) {
                options = $.extend({}, options, {
                    ajax: {
                        settings: {
                            data: $(this).data('ajax-data'),
                        }
                    }
                });
            }
            if ($(this).data('popup-class')) {
                options = $.extend({}, options, {
                    baseClass: options.baseClass + ' ' + $(this).data('popup-class')
                });
            }
            $(this).fancybox(options);
        }).addClass("fancybox-inited");
    }
}








/*

Slider

 */


function slider_init($s, context) {
    slider_swiper_init($s, context);
}

function slider_swiper_init($s, context, on_init) {
    if (typeof Swiper !== "undefined") {

        if (typeof $s === "undefined" || !$s) {
            $s = context ? $('.js-swiper', context) : $('.js-swiper');
            $s = $s.not(".js-swiper-inited").not(".js-swiper-on-demand");
        }

        $s.each(function () {
            var $this = $(this);
            var defaults = {
                init: false,
                fadeEffect: {
                    crossFade: true
                },
                navigation: {
                    prevEl: $this.find('.swiper-button-prev'),
                    nextEl: $this.find('.swiper-button-next'),
                },
                pagination: {
                    clickable: true,
                    renderFraction: function (currentClass, totalClass) {
                        return '<span class="' + currentClass + '"></span>' + '/' + '<span class="' + totalClass + '"></span>';
                    }
                },
                autoHeight: false,
                buttonDisabledClass: 'disabled',
                roundLengths: true,
                loopPreventsSlide: false,
                resistanceRatio: 0,
                speed: 800,
            };
            var options = defaults;
            options = $.extend(true, {}, defaults, $this.data('swiper'));

            if (options.lazy && typeof options.lazy.loadOnTransitionStart == 'undefined') {
                if (typeof options.lazy == 'boolean') {
                    options.lazy = {};
                }
                options.lazy.loadOnTransitionStart = true;
            }

            $(this).each(function () {
                var $this = $(this);
                if (typeof $this.data("swiper-thumbs") !== "undefined") {
                    var swiper = $this.find('.swiper-container')[0].swiper;
                    var $thumbs_swiper = $($this.data("swiper-thumbs"));
                    if ($thumbs_swiper.length) {
                        var $thumbs_swiper_cs = $thumbs_swiper.find('.swiper-container');
                        if (typeof $thumbs_swiper_cs[0].swiper === "undefined") {
                            slider_swiper_init($thumbs_swiper);
                            if (typeof $thumbs_swiper.find('.swiper-container')[0].swiper !== "undefined") {
                                options.thumbs = {
                                    autoScrollOffset: 1
                                };
                                options.thumbs.swiper = $thumbs_swiper_cs[0].swiper;
                            }
                        }
                    }
                }
            });

            var swiper = new Swiper($this.find('.swiper-container')[0], options);

            swiper.on('transitionStart', function (e) {
                this.$el.trigger('transitionStart', [this]);
                var $activeSlide = $(this.slides).eq(this.activeIndex);
                $activeSlide.find(".swiper-lazy-preloader").css({
                    top: this.height / 2
                });
                var $activeSlide = $(this.slides).eq(this.activeIndex);
                $(this.slides).not($activeSlide).find('video').each(function(){
                    this.pause();
                });
            });

            swiper.on('transitionEnd', function () {
                this.$el.trigger('transitionEnd', [this]);
            });

            swiper.on('slideChange', function () {
                this.$el.trigger('slideChange', [this]);
            });

            if (on_init) {
                swiper.on('init', on_init);
            }

            swiper.on('init', function (e) {
                this.$el.trigger('init', [this]);
                if ($this.data('swiper-autoplay-manual')) {
                    this.autoplay.stop();
                }
            });

            swiper.on('init progress resize', function (e) {
                if (typeof this.allowTouchMoveRemembered === 'undefined') {
                    this.allowTouchMoveRemembered = this.allowTouchMove;
                }
                var $slider = $(this.$el).closest(".js-swiper");
                if (this.isBeginning && this.isEnd) {
                    $slider.addClass("swiper-buttons-unneeded");
                    $(this.navigation.$prevEl).addClass("swiper-button-unneeded");
                    $(this.navigation.$nextEl).addClass("swiper-button-unneeded");
                    this.allowTouchMove = false;
                } else {
                    $slider.removeClass("swiper-buttons-unneeded");
                    $(this.navigation.$prevEl).removeClass("swiper-button-unneeded");
                    $(this.navigation.$nextEl).removeClass("swiper-button-unneeded");
                    if (typeof this.allowTouchMoveRemembered !== 'undefined') {
                        this.allowTouchMove = this.allowTouchMoveRemembered;
                    }
                }
            });

            swiper.on('progress', function (progress) {
                this.$el.trigger('progress', [this]);
                progressCallback(this);
            });
            progressCallback(swiper);


            swiper.on('lazyImageReady', function (slideEl, imageEl) {
                setTimeout(function(){
                    var $o = $(imageEl).closest('.img-to-bg');
                    img_to_bg($o);
                    $o.addClass('img-to-bg-lazy-loaded');
                }, 0);
                this.updateAutoHeight();
            });

            swiper.on('transitionEnd transitionStart', $.throttle(50, function () {
                if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
                    Blazy.revalidate();
                }
            }));

            swiper.init();

        }).addClass("js-swiper-inited");

        function progressCallback(swiper) {
            var $slider = $(swiper.$el).closest(".js-swiper");
            $slider.removeClass("js-swiper--start js-swiper--end");
            if (swiper.isBeginning) {
                $slider.addClass("js-swiper--start");
            }
            if (swiper.isEnd) {
                $slider.addClass("js-swiper--end");
            }
        }

        $("body").on("responsive-update-no-animation", function () {
            delay_slider_resize(function () {
                $(".js-swiper.js-swiper-inited").each(function () {
                    if ($(this).data("reinitialize-on-resize") && typeof $(this).find(".swiper-container")[0].swiper !== "undefined") {
                        var swiper = $(this).find(".swiper-container")[0].swiper;
                        var index = swiper.realIndex;
                        swiper.destroy(true, true);
                        $(this).find('.swiper-slide-duplicate').remove();
                        slider_swiper_init($(this), function (e) {
                            this.slideTo(index, 0);
                        });
                    }
                });
            }, 300);
        });
    }
}

function slider_swiper_on_demand() {
    var $s = $(this).closest('.js-swiper-on-demand');
    if ($s.length) {
        slider_init($s);
    }
    else {
        var $s = $(this).closest('.js-swiper-on-demand-wrapper').find('.js-swiper-on-demand');
        slider_init($s);
    }
}

var delay_slider_resize = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

var delay_blazy_trigger = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();