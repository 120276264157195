


/*

Custom Checkboxes

 */

function checkbox_plain_init($o, context) {
    if (!$o) {
        $o = context?$('.js-checkbox', context):$('.js-checkbox');
    }
    $o = $o.not(".js-checkbox-inited");
    $o.find("[type='checkbox'], [type='radio']").on("change change-pseudo", function(e){
        var $o = $(this).closest(".js-checkbox");
        var name = $(this).attr("name");
        if ($(this).prop("type") == 'radio') {
            var $radios = $(this).closest("form").find("[name='"+name+"']");
            if (!$radios.length) $radios = $("input[name='"+name+"']");
            $radios = $radios.not(':checked');
            $radios.closest(".js-checkbox").removeClass("checked");
        }
        if ($(this).prop("checked")) $o.addClass("checked");
        else $o.removeClass("checked");
        if ($(this).prop("disabled")) $o.addClass("disabled");
        else $o.removeClass("disabled");
    }).on("focus", function(e){
        var $o = $(this).closest(".js-checkbox");
        if (!$(this).prop("disabled")) $o.addClass("focused");
    }).on("blur", function(e){
        var $o = $(this).closest(".js-checkbox");
        $o.removeClass("focused");
    }).on('click', function(e){
        var $input = $(this).data("uncheckable");
        if (typeof $(this).data("uncheckable") !== 'undefined') {
            var previousValue = $(this).attr('data-previousValue');
            var name = $(this).attr('name');
            var $radios = $(this).closest("form").find("[name='"+name+"']");
            if (!$radios.length) $radios = $("input[name='"+name+"']");
            if (previousValue == 'checked') {
                $(this).prop('checked', false).trigger('change');
                $(this).attr('data-previousValue', false);
            } else {
                $radios.attr('data-previousValue', false);
                $(this).attr('data-previousValue', 'checked');
            }
        }
    }).each(function(){
        var $o = $(this).closest(".js-checkbox");
        if ($(this).attr("checked")) $o.addClass("checked");//?? prop
        if ($(this).attr("disabled")) $o.addClass("disabled");//?? prop
    });
    $o.addClass("js-checkbox-inited");
}


/*

Autosize Textarea

 */

function textfield_autosize_init($o, context)
{
    if (!$o) {
        $o = context?$('.js-autosize', context):$('.js-autosize');
    }
    $o.not(".js-autosize-inited").each(function(){
        autosize($(this));
        $(this).addClass("js-autosize-inited");
    });
}